.Home {
  &__Logo {
    max-height: 90px;
    object-fit: contain;
    // width: 128px;
  }
  &__Title {
    font-size: 24px;
    text-align: center;
    &__Failed {
      font-weight: bold;
      margin-top: 32px;
      margin-bottom: 0px;
    }
  }
  &__ManualCalc {
    display: flex;
    flex-direction: column;
    &__Title {
      font-size: 16px;
      text-align: center;
      // text-decoration: underline;
      font-weight: 100;
    }
    &__Options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 140px;
      margin-bottom: 32px;
    }
    &__Option {
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 50%;
      &__Title {
        font-size: 24px;
        text-align: center;
        font-weight: 900;
        color: #28a1d5;
      }
    }
  }
  &__Cost {
    display: flex;
    gap: 16px;
    margin-bottom: 48px;
    flex-wrap: wrap;
    &__Link {
      text-align: center;
      width: 100%;
      text-decoration: none;
      color: #444;
    }
    &__CTA {
      flex-direction: row !important;
      justify-content: center !important;
      gap: 16px;
      button {
        background: #444;
        font-weight: bold !important;
        border-radius: 18px !important;
        text-transform: uppercase;
        &:hover {
          background: #333 !important;
        }
      }
    }
    &__Box {
      flex: 1 1 21%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      &__CustomQuote {
        height: 40px !important;
        width: 180px;
        border: none;
        background: none;
        input {
          background-color: #ffffff !important;
            border: 2px solid #efefef;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 24px !important;
            box-sizing: border-box;
            height: 40px !important;
            width: 100% !important;
          height: 40px !important;
          line-height: 40px;
          font-family: 'Bungee', cursive;
          text-align: center !important;
          font-size: 24px !important;
        }
        .ant-input-number-handler-wrap {
          display: none !important;
        }
      }
      &__NormalWeight{
        font-weight: 100;
      }
      &__Submit {
        margin-top: 24px;
        background: #444444;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 18px;
        &:hover {
          background: #333 !important;
        }
        // width: 210px;
      }
      &__Ammount {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Bungee', cursive;
        font-size: 24px;
        &__NoCheck {
          padding-bottom: 22px;
        }
      }
      &__Title {
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 32px;
        /* or 133% */

        display: flex;
        align-items: center;
        letter-spacing: -0.02em;

        color: #4798c5;
      }
    }
  }
}

.ant-upload {
  &-drag {
    background: #fafcfe !important;
    border-color: #28a1d5 !important;
    &-icon {
      margin: 0;
      margin-bottom: 8px !important;
    }
  }
  &-text {
    color: #28a1d5 !important;
    font-weight: bold;
  }
  &-hint {
    color: #8f9bba !important;
  }
  &-list {
    display: none;
  }
  &-btn {
    padding: 4px !important;
    padding-top: 16px !important;
  }
}

.ManualBox {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  height: 100% !important;
  &__Hint {
    text-align: center;
    width: 100%;
    color: #636363;
  }
  small {
    opacity: 0.3;
    font-size: 11px;
    font-variant: small-caps;
    text-transform: lowercase;
  }
  &__Result {
    &,
    & * {
      font-family: 'Bungee', cursive !important;
    }
    input {
      text-align: center !important;
    }
    font-size: 24px;
  }
  &__Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 16px;
    & > .ant-form-item {
      flex-grow: 1;
    }
    &__Center {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
  input{
    background: #ffffff;
    border: 2px solid #efefef;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 24px !important;
    box-sizing: border-box;
    height: 40px !important;
    width: 100% !important;
  }
  label {
    font-size: 15px !important;
    text-align: center;
  }
  .ant-form-item-label {
    text-align: center;
  }
  .ant-input-number {
    width: 100% !important;
    border-radius: 24px !important;
  }
  .ant-input-number-handler-wrap {
    display: none !important;
  }
  button {
    background: #444444;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 18px;
    width: 210px;
    &:hover {
      background: #333 !important;
    }
  }
}

@media (max-width: 840px) {
  body {
    background: #f4f7fe;
  }
  .App {
    width: 90vw;
    flex-direction: column;
  }
  .Home {
    &__ManualCalc {
      &__Options {
        flex-direction: column;
        gap: 0;
      }
      &__Option {
        width: 100%;
      }
    }
    &__Cost {
      flex-direction: column;
      gap: 16px;
    }
  }
  .ManualBox {
    &__Row {
      flex-direction: column;
      gap: 8px;
    }
    &__Result {
      input {
        text-align: center;
      }
    }
  }
}


.showMouseClick{
  cursor: pointer;
  user-select: none;
}
.break {
  flex-basis: 100%;
  height: 0;
}

.Failed {
  &__Text {
    text-align: center;
  }
}

.ant-form-item-control {
  display: flex;
  justify-content: center;
  align-items: center;
}